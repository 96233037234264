import { commonMetas } from '@components/common/constant';
import Metacategories from '@components/common/metaJson/category.json';

export const RemoteCategoriesMetaTags = (props: any) => {
  // Collect all the generated meta tags
  const allMetaTags = Metacategories.flatMap((obj: any) => {
    // Get the first (and only) key of each object
    const key =
      Object.keys(obj).find((item) => item == props.categoryName) || '';
    // Access the nested object using that key and create meta tags
    return key && createMetaTags({ ...commonMetas[0], ...obj[key] }, props);
  });

  // Return the meta tags as JSX elements
  return <>{allMetaTags}</>;
};

function createMetaTags(data: { [key: string]: any }, props: any): any[] {
  const metaTags: any[] = [];
  // Loop over the properties of the data object
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      if (key === 'preconnect') {
        data[key].map((item: any) => {
          metaTags.push(
            <link rel="preconnect" href={item} crossOrigin="anonymous" />
          );
        });
      } else if (key === 'canonical' && !props.canonicalTwo) {
        metaTags.push(<link rel="canonical" href={data[key]} />);
      } else if (key === 'canonicalTwo' && props.canonicalTwo) {
        metaTags.push(<link rel="canonical" href={data['canonicalTwo']} />);
      } else if (key === 'title') {
        metaTags.push(<title key="title">{data[key]}</title>);
        metaTags.push(<meta name={key} content={String(data[key])} />);
      } else if (key === 'yoast-schema') {
        // Create script tag for yoast-schema
        metaTags.push(
          <script
            type="application/ld+json"
            className="yoast-schema-graph"
            key="yoast-schema"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(data[key]) }}
          />
        );
      } else if (typeof data[key] === 'object' && data[key] !== null) {
        // Recursively handle nested objects
        for (const nestedKey in data[key]) {
          if (Object.prototype.hasOwnProperty.call(data[key], nestedKey)) {
            metaTags.push(
              <meta
                name={`${key}:${nestedKey}`}
                content={String(data[key][nestedKey])}
                key={`${key}:${nestedKey}`}
              />
            );
          }
        }
      } else if (!['canonicalTwo', 'canonical'].includes(key)) {
        // Create meta tag for non-object properties
          metaTags.push(
            <meta name={key} content={String(data[key])} key={key} />
          );
      }
    }
  }
  return metaTags;
}
